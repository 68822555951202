import { OVERLAYS } from './constants';

export enum Breakpoint {
  Phone,
  TabletPortrait,
  TabletLandscape,
  Desktop,
  WebFlowTablet
}

export type Breakpoints<T> = Partial<Record<Breakpoint, T>>;

export enum Method {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE'
}

export type Validator<V> = [(input: string, values: V) => boolean | Promise<boolean>, string];

export type Overlay = keyof typeof OVERLAYS;

export type OverlayProps = { visible: boolean };

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ArrayValue<T> = T extends Array<infer V> ? V : never;

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K]
}

export enum DeliveryDay {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4
}

export enum WeekendDay {
  Saturday = 5,
  Sunday = 6
}

export enum Role {
  Location = 'Location',
  Company = 'Company',
  Admin = 'Admin'
}

export enum LinkStatus {
  Pending = 1,
  Accepted = 2,
  Rejected = 3
}

export enum ProductState {
  Published = 0,
  SoldOut = 1,
  Invisible = 2,
  Deleted = 3
}

export enum OrderState {
  Draft = 1,
  Final = 2,
  Processed = 3,
  Rejected = 4,
  Cancelled = 5
}

export enum DayState {
  Open = 1,
  Closed = 2
}
